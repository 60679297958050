<template>
  <div class="app">
    <div class="card">
      <div class="header">用户需求+项目需求：量身打造云端全阶段服务</div>
      <div class="list">
        <div class="item" v-for="item in list" :key="item.title">
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="money">
              <span class="value">{{item.money}}</span>
              <span>{{ item.money > 0 ? '万/年' : '元'}}</span>
            </div>
            <div class="money" v-if="item.money2 > 0">
              <div class="or">or</div>
              <span class="value">{{item.money2}}</span>
              <span>万/组</span>
            </div>
            <div class="free" v-else>免费使用</div>
            <div class="money" v-if="item.money3" style="margin-top: 20px">
              <span class="value">{{item.money3}}</span>
              <span>万/定制模块</span>
            </div>
          </div>

          <div class="tags">
            <div class="tag" v-for="element in item.tags" :key="item.title + element">
              <a-icon type="check" class="icon" />
              {{element}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "体验版",
          money: 0,
          money2: 0,
          tags: ["获得基本方案及预估单体造价", "获得单体BIM渲染图"]
        },
        {
          title: "基础班",
          money: 20,
          money2: 4,
          tags: ["获得总平面布置图三维渲染图", "下载单体各专业初设图纸"]
        },
        {
          title: "进阶版",
          money: 35,
          money2: 7,
          tags: [
            "下载总图平面图/高程图/管线图",
            "下载单体各专业初设图纸",
            "下载单体各专业初设BIM"
          ]
        },
        {
          title: "专业版",
          money: 60,
          money2: 10,
          money3: "30-60",
          tags: [
            "下载总图及管线三维BIM模型",
            "下载单体各专业施工图",
            "下载单体各专业施工BIM",
            "配合施工阶段快速施工图变更",
            "根据需求定制智能设计模块"
          ]
        }
      ]
    };
  },

  methods: {}
};
</script>

<style lang="less" scoped>
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header {
  font-family: title;
  font-size: 40px;
  color: #323232;
  background: linear-gradient(90deg, #1c57af 0%, #232948 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 56px;
}

.list {
  display: flex;
  gap: 1px;
  background-color: #d3d3d3;
  border-radius: 30px;

  .item {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: relative;
    // z-index: 0;

    .info {
      flex: 1;
      padding-bottom: 28px;
    }

    .title {
      font-size: 30px;
      font-weight: 600;
      color: #323232;
      padding-top: 32px;
      margin-bottom: 24px;
      text-align: center;
    }
    .money {
      text-align: center;
      display: flex;
      align-items: baseline;
      justify-content: center;
      .value {
        font-size: 2.8em;
        color: #000;
        font-weight: 500;
        padding-right: 4px;
      }
    }

    .or {
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background-color: #297aff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      line-height: 100%;
      position: relative;
      top: -6px;
      left: -8px;
    }

    .free {
      margin-top: 12px;
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      color: #02b300;
    }

    .tags {
      border-top: 1px solid #d3d3d3;
      padding: 24px;
      font-size: 17px;
      height: 15em;
      background: #fcfcfc;

      .tag {
        color: #3e4562;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          color: #297aff;
        }
      }
    }
  }

  .item::before {
    content: "";
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    height: 18px;
    background-color: #02b300;
  }
  .item:first-child::before {
    border-top-left-radius: 30px;
  }
  .item:nth-child(2)::before {
    background-color: #5797ff;
  }
  .item:nth-child(3)::before {
    background-color: #bd79ff;
  }
  .item:last-child::before {
    border-top-right-radius: 30px;
    background-color: #ff8134;
  }

  //   .item:first-child {
  //     border-top-left-radius: 30px;
  //   }
  //   .item:last-child {
  //     border-top-right-radius: 30px;
  //   }
}
</style>
